<template>
    <modal ref="modalCategorias" titulo="Categorías" tamano="modal-xl" no-aceptar>
        <div class="d-middle mx-0 bg-fondo w-100 position-absolute header-modal">
            <div class="col d-middle-center px-3 h-100 border-right text-center text-general f-16">
                Selecciona las categorias
            </div>
            <div class="col d-middle px-3 h-100 border-right text-general f-16">
                Categorías listada
            </div>
            <div class="col d-middle px-3 h-100 text-general f-16">
                Parámetros
            </div>
        </div>
        <div class="row mx-0 py-4" />
        <div class="row mx-0 pt-1">
            <div class="column-left border-right">
                <div class="row mx-0 justify-center mb-3 pt-3">
                    <div class="col-8">
                        <el-input v-model="buscarCategoria" placeholder="Buscar categoria" prefix-icon="icon-search" autocomplete="nope" size="small" class="w-100 br-20" />
                    </div>
                </div>
                <div class="row mx-0 justify-center align-items-center overflow-auto custom-scroll" style="max-height: calc(100vh - 542px);">
                    <div
                    v-for="(c,key) in categorias.filter(data => !buscarCategoria || data.nombre.toLowerCase().includes(buscarCategoria.toLowerCase()))"
                    :key="key" class="col-8 pl-4 d-middle mb-3"
                    >
                        <el-checkbox v-model="c.checked" class="check-green mt-1" @change="selectCategoria($event,c.id)" />
                        <div class="col-auto text-general f-16">
                            {{ c.nombre }} ({{ c.cant_productos }})
                        </div>
                    </div>
                </div>
            </div>
            <draggable tag="div" :list="categoriasListada" class="column-center border-right px-3 overflow-auto custom-scroll" style="max-height: calc(100vh - 480px);" handle=".handle" @change="onDrag">
                <div v-for="(catListada, idx) in categoriasListada" :key="idx" class="row mx-0 pt-3">
                    <i v-if="seccion.parametro == 53" class="icon-drag handle f-20 text-grey" />
                    <div class="col px-2 f-16 text-general">
                        {{ catListada.nombre }} ({{ catListada.cant_productos }})
                    </div>
                    <i class="icon-delete-trash f-18 text-general cr-pointer ml-auto" @click="deleteCategoria(catListada.id)" />
                </div>
            </draggable>
            <div class="column-right px-3 pt-3">
                <p class="text-general f-16 mb-3">
                    Líneas del listado
                </p>
                <div class="row mx-0 mb-5">
                    <button type="button" class="box-lineas mr-3" :class="seccion.tipo == 31 ? 'bg-gris' : '' " @click="actualizarSeccion(31,'tipo')">
                        1
                    </button>
                    <button type="button" class="box-lineas" :class="seccion.tipo == 32 ? 'bg-gris' : '' " @click="actualizarSeccion(32,'tipo')">
                        2
                    </button>
                </div>
                <p class="text-general f-16 mb-3">
                    Ordenar por
                </p>
                <el-radio-group v-model="seccion.parametro">
                    <div class="row mx-0 mb-3">
                        <el-radio :label="41" class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Alfabeticamente descendente
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="42" class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Alfabeticamente ascendente
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="43" class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Más clicks
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="44" class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Menos clicks
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="51" class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Más productos
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="52" class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Menos productos
                        </el-radio>
                    </div>
                    <div class="row mx-0 mb-3">
                        <el-radio :label="53" class="radio-green" @change="actualizarSeccion($event,'parametro')">
                            Personalizado
                        </el-radio>
                    </div>
                </el-radio-group>
            </div>
        </div>
    </modal>
</template>

<script>
import LandingCategorias from '~/services/landing/landingCategorias'
export default {
    data(){
        return {
            buscarCategoria: '',
            categoria: false,
            categoriasListada: [],
            formato: '1',
            idLandingSeccion: null,
            categorias: [],
            seccion: {}
        }
    },
    computed: {
        idLanding(){
            return this.$route.params.idLanding
        }
    },
    watch: {

    },
    mounted(){

    },
    methods: {
        toggle(idSeccion = null){
            this.categorias = [];
            this.categoriasListada = [];
            if (idSeccion){
                this.idLandingSeccion = idSeccion
                this.detalleLandingSeccion();
            }else{
                this.postLandingSeccion();
            }
            this.$refs.modalCategorias.toggle();
        },
        async postLandingSeccion(){
            try {
                const { data } = await LandingCategorias.postLandingSeccion({idLanding: this.idLanding});
                this.idLandingSeccion = data?.data.id
                this.detalleLandingSeccion();
            } catch (e){
                this.error_catch(e)
            }
        },
        async detalleLandingSeccion(){
            try {
                const { data } = await LandingCategorias.detalleLandingSeccion({idSeccion: this.idLandingSeccion});
                this.seccion = data?.data
                this.selectCategorias();
            } catch (e){
                this.error_catch(e)
            }
        },
        async selectCategorias(){
            try {
                const { data } = await LandingCategorias.selectCategorias({idSeccion: this.idLandingSeccion});
                this.categorias = data?.data
                this.getCategorias();
            } catch (e){
                this.error_catch(e)
            }
        },
        async getCategorias(){
            try {
                const { data } = await LandingCategorias.getCategorias({idSeccion: this.idLandingSeccion, ordenar: this.seccion.parametro});
                this.categoriasListada = data?.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async selectCategoria(value,idCategoria){
            if (value){
                this.postCategoria(idCategoria)
            }else {
                this.deleteCategoria(idCategoria)
            }
        },
        async postCategoria(idCategoria){
            try {
                await LandingCategorias.postCategoria({idCategoria, idSeccion: this.idLandingSeccion});
                this.getCategorias();
                this.emitirCambios('post');
            } catch (e){
                this.error_catch(e)
            }
        },
        async deleteCategoria(idCategoria){
            try {
                await LandingCategorias.deleteCategoria({idCategoria, idSeccion: this.idLandingSeccion});

                this.categoriasListada = this.categoriasListada.filter(e => e.id != idCategoria)

                let categoria = this.categorias.find(c=>c.id == idCategoria)
                categoria.checked = false
                this.emitirCambios('delete');

            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizarSeccion(value,campo){
            try {
                if (campo == 'tipo' && this.seccion.tipo != value || campo == 'parametro'){
                    const { data } = await LandingCategorias.actualizarSeccion({campo: campo, valor: value, idSeccion: this.idLandingSeccion});
                    this.seccion.tipo = data.data.tipo
                    if (campo == 'parametro'){
                        this.seccion.parametro = data.data.parametro
                        this.getCategorias();
                    }
                    if(campo == 'tipo'){
                        this.$emit('updateParametro');
                    }
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async onDrag(datos){
            try {
                let payload = {
                    nuevaPosicion: datos.moved.newIndex + 1,
                    antiguaPosicion: datos.moved.oldIndex + 1,
                    idElemento: datos.moved.element.idElemento,
                    idSeccion: this.idLandingSeccion
                }

                await LandingCategorias.cambiarPosicion(payload)
                this.getCategorias();
				this.notificacion('', 'Posición actualizada correctamente', 'success' )

            } catch (error){
                this.error_catch(error)
            }

        },

        emitirCambios(bandera){
            let valid = this.categorias.filter((item) => item.checked === true).length;
            if(bandera === 'post'){
                if(valid < 2){
                    this.$store.commit('landing/landing/setListenerUpdater', { value:'categoria', type: 'nuevaData', idSection: null });
                } else {
                    this.$store.commit('landing/landing/setListenerUpdater', { value:'categoria', type: 'actualizarData', idSection: this.idLandingSeccion });
                }
            }

            if(bandera === 'delete'){
                this.$store.commit('landing/landing/setListenerUpdater', { value:'categoria', type: 'actualizarData', idSection: this.idLandingSeccion });
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.header-modal{
    height: 53px;
    left: 0px;
}

.box-lineas{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DFE4E8;
    background-color: #FFFFFF;
    border-radius: 8px;
    &:focus{
        background-color: #F6F9FB;
    }
}

.column-left{
    width:362px;
    @media (min-width:320px) and (max-width:1024px){
        width: 251px !important;
    }
}

.column-center{
    width:378px;
    @media (min-width:320px) and (max-width:1024px){
        width: 267px !important;
    }
}

.column-right{
    width:352px;
    @media (min-width:320px) and (max-width:1024px){
        width: 250px !important;
    }
}

/* -*-*-*-*- Media Queries -*-*-*-*- */

</style>
